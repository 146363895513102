
import MixMenu from 'MixMenu';
// @group Molecules
// @vuese
// Navigation fitting for the header. Supports three levels<br><br>
// **SASS-path:** _./styles/components/molecules/ca-header-navigation.scss_
export default {
  name: 'CaHeaderNavigation',
  mixins: [MixMenu],
  props: {
    // What type/style of menu should be displayed
    type: {
      // `full-width` / `boxed`
      type: String,
      default: 'full-width',
    },
    // Show the "show all" link in the menu
    showAllLink: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    modifiers() {
      return 'ca-header-navigation--' + this.type;
    },
    containerElem() {
      return this.type === 'full-width' ? 'CaContainer' : 'div';
    },
  },
  methods: {
    getElem(item) {
      return item.type === 'category' ? 'button' : this.getBaseElem(item);
    },
    getAttrs(item) {
      return item.type === 'category'
        ? {
            type: 'button',
          }
        : this.getAttributes(item);
    },
  },
};
