
/*
  The main header component for the site.
*/
export default {
  name: 'CaHeader',
  mixins: [],
  props: {},
  data: () => ({
    searchOpened: false,
    hideHeader: false,
  }),
  computed: {
    modifiers() {
      return {
        'ca-header--scrolled': !this.$store.getters.siteIsAtTop,
        'ca-header--hidden': this.hideHeader,
      };
    },
  },
  watch: {},
  mounted() {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'setHeaderHidden') {
        const eventData = mutation.payload;
        if (eventData && !this.hideHeader) {
          this.hideHeader = eventData;
        } else if (!eventData && this.hideHeader) {
          this.hideHeader = eventData;
        }
      }
    });
  },
  methods: {},
};
